import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import * as models from './models';
import * as cfg from './config';
import * as aux from 'adcore-auxiliary';
import * as man from 'adcore-manipulations';
import {Subject} from 'rxjs';
import * as _ from 'lodash';
import {environment} from '../../environments/environment';
import * as utils from '../helpers/utils';
import moment from 'moment';
import {MenuItem} from 'primeng/api';
import {IMediaBlastPaymentProfile} from './models';


@Injectable({
  providedIn: 'root'
})
export class MediaService implements OnDestroy {
  private serviceDestroyed: Subject<boolean> = new Subject();
  currentCountryCode = '';
  currentAppName: string | null = null;
  semdocUrlWithParameters = '';
  changingAdvertiser: EventEmitter<aux.IMediaSelectedEvent> = new EventEmitter<aux.IMediaSelectedEvent>(false);
  changingFeed: EventEmitter<aux.IMediaSelectedEvent> = new EventEmitter<aux.IMediaSelectedEvent>(false);
  changingAccount: EventEmitter<aux.IMediaSelectedEvent> = new EventEmitter<aux.IMediaSelectedEvent>(false);
  changingVersion: EventEmitter<aux.IMediaSelectedEvent> = new EventEmitter<aux.IMediaSelectedEvent>(false);
  changingVersionPanel: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  changingPage: EventEmitter<aux.IMediaSelectedEvent> = new EventEmitter<aux.IMediaSelectedEvent>(false);
  changingAssets: EventEmitter<void> = new EventEmitter<void>(false);
  changingTimePeriod: EventEmitter<void> = new EventEmitter<void>(false);
  changingAlertNotice: EventEmitter<void> = new EventEmitter<void>(false);
  feeditorMenuOutsideClick: EventEmitter<aux.IMediaSelectedEvent> = new EventEmitter<aux.IMediaSelectedEvent>(false);
  openingPanel: EventEmitter<aux.IMediaSelectedEvent> = new EventEmitter<aux.IMediaSelectedEvent>(false);
  jobStarting: EventEmitter<string> = new EventEmitter<string>(false);
  isAuthenticationInProcess = false;
  isLoadMoreDataInProcess = false;
  isLoadingMedia = false;
  isLoadingHurrican = false;
  isAlerterScanning = false;
  isAssetsChanging = false;
  alerterSettings: any = null;
  hasAsset = {
    website: false,
    googleAds: false,
    microsoftAds: false,
    googleMerchant: false,
    productFeed: false,
    version: false
  };
  semdocSwitchTab = false;
  hurricaneSwitchTab = false;
  mediaBlastSwitchTab = false;
  alerterSwitchTab = false;
  firstTimeOpenFeeditor = true;
  timePeriod: aux.ITimePeriod = {type: aux.ETimePeriod.LAST_RUN};
  colorApp = '';
  menuAssets: MenuItem[] = [];
  isOldUIInProcessReported = false;
  currentUser: models.IUser | null = null;
  private currentToken: string | null = null;
  userAdvertiser: models.IAdvertiser | null = null;
  private _currentAdvertiser: aux.IMediaRow | null = null;
  private _currentFeed: aux.IMediaRow | null = null;
  private _currentAccount: aux.IMediaRow | null = null;
  private _currentVersion: aux.IMediaRow | null = null;
  private _currentTimePeriod: aux.ITimePeriod | null = null;
  allPages: aux.IMediaRow[] = [];
  private _currentPage: aux.IMediaRow | null = null;
  currentAccountChannel: aux.IMediaRow | null = null;
  currentAccountParent: aux.IMediaRow | null = null;
  managementPage: string | undefined | null = null;
  userAdvertiserAndKids: aux.IMediaRow[] = [];
  parentAdvertiser: aux.IMediaRow | null = null;
  useCache = true;
  disableClear = false;
  private _currentDynamicInfo: models.IDynamicInfo | null = null;
  private _currentJobsInfo: aux.IMediaRow[] = [];
  currentPageName: string | null = null; // used only in routing main component
  effortlessInstance: any = null;
  feeditorMergedColumns: any[] = [];
  feeditorCurrentTotalRows = 0;
  feeditorRenderException: string | null = null;
  feeditorCacheBuildDate: string | null = null;
  isExistAction = false;
  isDynamicActionExist = false;
  isShoppingActionExist = false;
  private _today = moment(new Date());
  semdocGeneralSettings: models.ISemdocGeneralSettings | undefined;
  feeditorPlusCurrentRules: man.IRule[] = [];
  feeditorPlusTotalVersions = cfg.MAX_FEEDITOR_PLUS_ACTIVE_VERSIONS;
  feeditorPlusApplyingVersion: EventEmitter<void> = new EventEmitter<void>(false);
  feeditorPlusFeedIsLoading = false;
  feeditorPlusImportInfo: any = null;
  constructor(private windowRefService: aux.WindowRefService) {
  }

  get isMediaBlastAdvertiser(): boolean {
     return this.currentAppName === 'mediablast' || !!this.selectedAdvertiser?.modules?.mediablast;
  }

  get isMediaBlastSubscriptionActive(): boolean {
    return this.isMediaBlastAdvertiser &&
      this.mediaBlastPaymentProfile.subscriptionStatus === 'active';
      // && !!this.mediaBlastPaymentProfile.subscriptionId; // removed in order to support old mb clients
  }

  get isMediaBlastPageVisible(): boolean {
    return this.isMediaBlastSubscriptionActive || this.isSuperAdmin;
  }

  get isMBExistingOldClient(): boolean {
    return this.isMediaBlastSubscriptionActive && !this.mediaBlastPaymentProfile.subscriptionId;
  }
  get isEnterpriseUser(): boolean {
    return  [models.ESubscriptionType.FREE,
      models.ESubscriptionType.ENTERPRISE].includes( this.userAdvertiser?.planSettings?.subscriptionType ||
      models.ESubscriptionType.INITIAL);
  }

  get isEnterpriseAdvertiser(): boolean {
    return  [models.ESubscriptionType.FREE,
      models.ESubscriptionType.ENTERPRISE].includes( this.currentAdvertiser?.planSettings?.subscriptionType ||
      models.ESubscriptionType.INITIAL) || [models.ESubscriptionType.FREE,
      models.ESubscriptionType.ENTERPRISE].includes( this.parentAdvertiser?.planSettings?.subscriptionType ||
      models.ESubscriptionType.INITIAL);
  }
  get isAdvertiserSwitchPlan(): boolean {
    return this.currentAdvertiser?.switchPlan || false;
  }
  get isSubscribedUser(): boolean {
    return  [models.ESubscriptionType.TRIAL,
      models.ESubscriptionType.PAID, models.ESubscriptionType.CANCELED,
      ].includes( this.userAdvertiser?.planSettings?.subscriptionType ||
      models.ESubscriptionType.INITIAL);
  }
  get showStatsAndPlan(): boolean {
    // return this.userAdvertiser?.planSettings?.isLicenseOwner === 'yes' &&
    //   [models.ESubscriptionType.PAID, models.ESubscriptionType.TRIAL].
    //   includes(this.userAdvertiser?.planSettings?.subscriptionType || models.ESubscriptionType.INITIAL);
    // return this.planSettings?.isLicenseOwner === 'yes' &&
    //   [models.ESubscriptionType.PAID,
    //     models.ESubscriptionType.TRIAL,
    //     models.ESubscriptionType.CANCELED,
    //    ].includes(this.planSettings?.subscriptionType ||
    //     models.ESubscriptionType.INITIAL);
    return false;
  }

  get showPlanOnly(): boolean {
    if (this.isAdminRole) {
      return this.planSettings?.isLicenseOwner === 'yes';
    }
    return this.userAdvertiser?.planSettings?.isLicenseOwner === 'yes';
  }

  clearNotCachedVersion(): void {
    if (!this.disableClear) {
      this._currentVersion = null;
    }
  }

  clearNotCachedFeed(): void {
    this._currentFeed = null;
  }

  clearCachedFeed(): void {
    const sKey: cfg.ELocalStorageTokens =  cfg.tokenKey('last', this.currentAppName) || cfg.ELocalStorageTokens.LAST_FEED;
    const key = cfg.localStorageTokenPatch(sKey, this.currentUser?.email || 'no@email',
      this.selectedAdvertiserId || 'noAdvertiser');
    this.windowRefService.removeSessionData(key);
    this._currentFeed = null;
  }

  clearNotCachedAccount(): void {
    this._currentAccount = null;
  }

  ngOnDestroy(): void {
    this.serviceDestroyed.next(true);
    this.serviceDestroyed.complete();
  }

  get walletStatus(): string {
    return this.selectedAdvertiser?.mediaBlastProperties?.walletStatus || 'Suspended';
  }

  get currentDynamicInfo(): models.IDynamicInfo | null {
    if (this.currentAppName === 'views' && this.currentVersion) {
      return this._currentDynamicInfo;
    }
    return null;
  }

  set currentDynamicInfo(value: models.IDynamicInfo | null) {
    // console.log('currentDynamicInfo: ',{value});
    if (this.currentAppName === 'views' && this.currentVersion) {
      this._currentDynamicInfo = value;
      if (value) {
        const adKey = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_VERSION, this.currentUser?.email || 'no@email',
          this.selectedAdvertiserId || 'noAdvertiser', this.getCacheDynamicMediaKey('ad'));
        const kwsKey = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_VERSION, this.currentUser?.email || 'no@email',
          this.selectedAdvertiserId || 'noAdvertiser', this.getCacheDynamicMediaKey('keyword'));
        this.windowRefService.setSessionData(adKey, value.adDynamicViewIdRow);
        this.windowRefService.setSessionData(kwsKey, value.keywordDynamicViewIdRow);
      }
    } else {
      this._currentDynamicInfo = null;
    }
  }

  clearDynamicInfo(): void {
    this._currentDynamicInfo = null;
    const adKey = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_VERSION, this.currentUser?.email || 'no@email',
      this.selectedAdvertiserId || 'noAdvertiser', this.getCacheDynamicMediaKey('ad'));
    const kwsKey = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_VERSION, this.currentUser?.email || 'no@email',
      this.selectedAdvertiserId || 'noAdvertiser', this.getCacheDynamicMediaKey('keyword'));
    this.windowRefService.removeSessionData(adKey);
    this.windowRefService.removeSessionData(kwsKey);
  }

  updateJobInfo(jobRow?: aux.IMediaRow | null, isDeleteJob?: string[]): void {
    if (this.currentVersion && jobRow) {
      if (jobRow.visible && !this.currentJobsInfo.length) {
        this.currentJobsInfo = [jobRow];
      } else if (!jobRow.visible && this.currentJobsInfo.length) {
        this.currentJobsInfo = this.currentJobsInfo.filter(j => j.key !== jobRow.key);
      } else if (jobRow.visible && this.currentJobsInfo.length) {
        const exist = this.currentJobsInfo.findIndex(j => j.key === jobRow.key) > -1;
        if (exist) {
          this.currentJobsInfo = this.currentJobsInfo.map((j) => {
            return j.key !== jobRow.key ? j : jobRow;
          });
        } else {
          this.currentJobsInfo.push(jobRow);
        }
      }
    }
    if (this.currentVersion && isDeleteJob && isDeleteJob.length) {
      this.currentJobsInfo = this.currentJobsInfo.filter(j => !isDeleteJob.includes(j.key));
    }
    // console.log({cj: this.currentJobsInfo});
  }

  get currentJobsInfo(): aux.IMediaRow[] {
    if (this.currentVersion) {
      return this._currentJobsInfo || [];
    }
    return [];
  }

  set currentJobsInfo(value: aux.IMediaRow[]) {
    this._currentJobsInfo = value || [];
  }

  get accessToken(): string | null {
    if (!this.currentToken) {
      // this.currentToken = this.cookieService.get(cfg.ECookieTokens.TOKEN) || null;
      this.currentToken = this.windowRefService.getLocalData(cfg.ELocalStorageTokens.ADCORE_TOKEN);
    }
    return this.currentToken;
  }

  set accessToken(value: string | null) {
    this.currentToken = value;
    if (this.currentToken) {
      this.windowRefService.setLocalData(cfg.ELocalStorageTokens.ADCORE_TOKEN, value);
      // this.cookieService.set(cfg.ECookieTokens.TOKEN, this.currentToken, cfg.COOKIE_DAYS_EXPIRE, '/');
    } else {
      this.windowRefService.removeLocalData(cfg.ELocalStorageTokens.ADCORE_TOKEN);
      // this.cookieService.delete(cfg.ECookieTokens.TOKEN);
    }
  }

  // TODO sales management
  get selectedAdvertiserId(): string | null {
    if (this.useCache) {
      const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_ADVERTISER, this.currentUser?.email || 'no@email');
      this._currentAdvertiser = this.windowRefService.getLocalData(key);
    }
    return this._currentAdvertiser?.key || this.userAdvertiserId || null;
    // this.currentAdvertiserId = this.cookieService.get(cfg.ECookieTokens.ADVERTISER_TOKEN) || this.userAdvertiserId || null;
    // return this.currentAdvertiserId;
  }

  get selectedVersionId(): string | undefined {
    return this.currentVersion?.key;
  }

  get userAdvertiserId(): string | null {
    return this.userAdvertiser?._id || null;
  }

  get selectedAdvertiser(): models.IAdvertiser | null {
    return this.currentAdvertiser?.value as models.IAdvertiser || this.userAdvertiser || null;
  }

  get currentFeedUrl(): string | undefined {
    const feedData = this.currentFeed?.value?.data || null;
    return feedData?.feedUrl;
  }

  get currentAdvertiser(): aux.IMediaRow | null {
    if (!this._currentAdvertiser && this.useCache) {
      const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_ADVERTISER, this.currentUser?.email || 'no@email');
      this._currentAdvertiser = this.windowRefService.getLocalData(key);
    }
    return this._currentAdvertiser || {
      key: this.userAdvertiserId || '',
      label: this.userAdvertiser?.companyName || '',
      value: this.userAdvertiser,
      type: aux.EMediaType.ADVERTISER,
      subType: this.userAdvertiser?.type as aux.EMediaSubType,
      visible: true
    };
  }

  set currentAdvertiser(value: aux.IMediaRow | null) {
    this._currentAdvertiser = value;
    if (value && this.useCache) {
      this.saveCachedAdvertiser(value);
    }
    // this.selectedAdvertiserId = value?.key || null;
  }

  saveCachedAdvertiser(value: aux.IMediaRow | null): void {
    if (value) {
      const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_ADVERTISER, this.currentUser?.email || 'no@email');
      this.windowRefService.setLocalData(key, value);
    }
  }

  private getCacheDynamicMediaKey(page?: string): string {
    return `${page || 'noPage'}|${this.currentAccount?.key || 'noMedia'}`;
  }

  get CacheMediaKey(): string {
    let media;
    if (cfg.APPS_WITH_ACCOUNT.includes(this.currentAppName || '')) {
      media = this.currentAccount?.key;
    } else if (cfg.APPS_WITH_FEED.includes(this.currentAppName || '')) {
      media = this.currentFeed?.key;
    }
    return `${this.currentPage?.subType || 'noPage'}|${media || 'noMedia'}`;
  }

  get currentVersion(): aux.IMediaRow | null {
    if (!this._currentVersion && this.useCache) {
      const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_VERSION, this.currentUser?.email || 'no@email',
        this.selectedAdvertiserId || 'noAdvertiser', this.CacheMediaKey);
      this._currentVersion = this.windowRefService.getSessionData(key);
    }
    return  (!cfg.APPS_WITHOUT_VERSIONS.includes(this.currentAppName || '')) ? this._currentVersion : null;
  }

  set currentVersion(value: aux.IMediaRow | null) {
    this._currentVersion = value;
    if (this.useCache) {
      this.saveCachedVersion(value, this.CacheMediaKey);
    }
  }

  saveCachedVersion(value: aux.IMediaRow | null, mediaKey: string, advertiserId?: string): void {
    // media key ${this.currentPage?.subType || 'noPage'}|${media || 'noMedia'}`
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_VERSION, this.currentUser?.email || 'no@email',
      advertiserId || this.selectedAdvertiserId || 'noAdvertiser', mediaKey);
    if (value) {
      this.windowRefService.setSessionData(key, value);
    } else {
      this.windowRefService.removeSessionData(key);
    }
  }

  get currentFeed(): aux.IMediaRow | null {
    if (!this._currentFeed && this.useCache) {
      const cookieToken = cfg.tokenKey('last', this.currentAppName);
      if (cookieToken) {
        const key = cfg.localStorageTokenPatch(cookieToken, this.currentUser?.email || 'no@email',
          this.selectedAdvertiserId || 'noAdvertiser');
        this._currentFeed = this.windowRefService.getSessionData(key);
      }
    }
    return (cfg.APPS_WITH_FEED.includes(this.currentAppName || '')) ? this._currentFeed : null;
  }

  set currentFeed(value: aux.IMediaRow | null) {
    this._currentFeed = value;
    if (value && this.useCache) {
      this.saveCachedFeed(value);
    }
    else if (!value && this.useCache) {
      this.clearCachedFeed();
    }
  }

  saveCachedFeed(value: aux.IMediaRow | null, advertiserId?: string, appName?: string): void {
    if (value) {
      const cookieToken = cfg.tokenKey('last', appName || this.currentAppName);
      if (cookieToken) {
        const key = cfg.localStorageTokenPatch(cookieToken, this.currentUser?.email || 'no@email',
          advertiserId || this.selectedAdvertiserId || 'noAdvertiser');
        this.windowRefService.setSessionData(key, value);
      }
    }
  }

  get channelIsBing(): boolean {
    return this.currentChannelName === 'bing';
  }

  get currentChannelName(): string | null {
    // this.currentAccount?.value?.channelName.toLowerCase();
    return this.currentAccountChannel?.label.toLowerCase() || this.currentAccount?.value?.channelName?.toLowerCase() || null;
  }

  get currentAccount(): aux.IMediaRow | null {
    if (!this._currentAccount && this.useCache) {
      const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_ACCOUNT, this.currentUser?.email || 'no@email',
        this.selectedAdvertiserId || 'noAdvertiser');
      this._currentAccount = this.windowRefService.getSessionData(key);
    }
    return (cfg.APPS_WITH_ACCOUNT.includes(this.currentAppName || '')) ? this._currentAccount : null;
  }

  set currentAccount(value: aux.IMediaRow | null) {
    this._currentAccount = value;
    if (value && this.useCache) {
      this.saveCachedAccount(value);
    }
  }

  saveCachedAccount(value: aux.IMediaRow | null, advertiserId?: string): void {
    if (value) {
      const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_ACCOUNT, this.currentUser?.email || 'no@email',
        advertiserId || this.selectedAdvertiserId || 'noAdvertiser');
      this.windowRefService.setSessionData(key, value);
    }
  }


  saveCachedPage(value: aux.IMediaRow | null, appName: string): void {
    if (value) {
      const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_PAGE,
        this.currentUser?.email || 'no@email', appName);
      this.windowRefService.setLocalData(key, value);
    }
  }

  get currentPage(): aux.IMediaRow | null {
    if (!this._currentPage && this.useCache) {
      const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_PAGE,
        this.currentUser?.email || 'no@email', this.currentAppName || 'noApp');
      this._currentPage = this.windowRefService.getLocalData(key);
    }
    return this._currentPage || null;
  }

  set currentPage(value: aux.IMediaRow | null) {
    this._currentPage = value;
    if (value && this.useCache) {
      this.saveCachedPage(value, this.currentAppName || 'noaApp');
    }
  }

  get cockpitFilter(): any | null {
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.COCKPIT_FILTERS_TOKEN, this.currentUser?.email || 'no@email',
      this.selectedAdvertiserId || 'noAdvertiser');
    return this.windowRefService.getLocalData(key);
  }

  set cockpitFilter(value: any) {
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.COCKPIT_FILTERS_TOKEN, this.currentUser?.email || 'no@email',
      this.selectedAdvertiserId || 'noAdvertiser');
    this.windowRefService.setLocalData(key, value);
  }

  get topUpFilter(): any | null {
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.TOPUP_FILTERS_TOKEN, this.currentUser?.email || 'no@email',
      this.selectedAdvertiserId || 'noAdvertiser');
    return this.windowRefService.getLocalData(key);
  }

  set topUpFilter(value: any) {
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.TOPUP_FILTERS_TOKEN, this.currentUser?.email || 'no@email',
      this.selectedAdvertiserId || 'noAdvertiser');
    this.windowRefService.setLocalData(key, value);
  }

  get autoSaveConfig(): any | null {
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.AUTO_SAVE_TOKEN,
      this.currentUser?.email || 'no@email', this.currentAppName || 'noApp');
    return this.windowRefService.getLocalData(key);
  }

  set autoSaveConfig(value: any) {
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.AUTO_SAVE_TOKEN,
      this.currentUser?.email || 'no@email', this.currentAppName || 'noApp');
    if (value) {
      this.windowRefService.setLocalData(key, value);
    } else {
      this.windowRefService.removeLocalData(key);
    }
  }

  get feeditorModeConfig(): any | null {
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.FEEDITOR_WORKING_MODE_TOKEN, this.currentUser?.email || 'no@email',
      this.selectedAdvertiserId || 'noAdvertiser', this.CacheMediaKey);
    // const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.FEEDITOR_WORKING_MODE_TOKEN,
    //   this.currentUser?.email || 'no@email');
    let localData = this.windowRefService.getLocalData(key);
    if (!localData && (this.feeditorCurrentTotalRows > cfg.MAX_ROWS_AMOUNT || this.isItDemoAdvertiser)) {
      localData = {workMode: cfg.EFeeditorWorkModes.real_time_preview};
      this.windowRefService.setLocalData(key, localData);
    }
    return localData || {workMode: cfg.EFeeditorWorkModes.real_time};
  }

  set feeditorModeConfig(value: any) {
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.FEEDITOR_WORKING_MODE_TOKEN, this.currentUser?.email || 'no@email',
      this.selectedAdvertiserId || 'noAdvertiser', this.CacheMediaKey);
    // const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.FEEDITOR_WORKING_MODE_TOKEN,
    //   this.currentUser?.email || 'no@email');
    if (value) {
      this.windowRefService.setLocalData(key, value);
    } else {
      this.windowRefService.removeLocalData(key);
    }
  }

  get feeditorVersionPanelConfig(): any | null {
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.FEEDITOR_VERSION_PANEL_MODE_TOKEN, this.currentUser?.email || 'no@email',
      this.selectedAdvertiserId || 'noAdvertiser', this.CacheMediaKey);
    let localData = this.windowRefService.getLocalData(key);
    if (!localData) {
      localData = {visible: true};
      this.windowRefService.setLocalData(key, localData);
    }
    return localData.visible;
  }

  set feeditorVersionPanelConfig(value: boolean | undefined | null) {
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.FEEDITOR_VERSION_PANEL_MODE_TOKEN, this.currentUser?.email || 'no@email',
      this.selectedAdvertiserId || 'noAdvertiser', this.CacheMediaKey);
    if (typeof value === 'boolean') {
      this.windowRefService.setLocalData(key, {visible: value});
    } else {
      this.windowRefService.removeLocalData(key);
    }
  }

  get modules(): any {
    return this.selectedAdvertiser?.modules || {};
  }

  get pureEffortless(): boolean {
    return Boolean(this.modules.effortless_feed && !this.isAdminRole);
  }

  get allowedEffortless(): boolean {
    return Boolean(this.modules.effortless_feed && this.isAdminRole && !this.effortlessInstance);
    // return this.isAdminRole;
  }

  get recentAdvertisers(): aux.IMediaRow[] {
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.RECENT_ADVERTISERS, this.currentUser?.email || 'no@email');
    const ld = this.windowRefService.getLocalData(key);
    return ld?.recent || [];
  }

  set recentAdvertisers(value: aux.IMediaRow[]) {
    if (this.useCache) {
      const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.RECENT_ADVERTISERS, this.currentUser?.email || 'no@email');
      this.windowRefService.setLocalData(key, {recent: value});
    }
  }

  get recentFeeds(): aux.IMediaRow[] {
    let ld: any = null;
    const cookieToken = cfg.tokenKey('recent', this.currentAppName);
    if (cookieToken) {
      const key = cfg.localStorageTokenPatch(cookieToken, this.currentUser?.email || 'no@email',
        this.selectedAdvertiserId || 'noAdvertiser');
      ld = this.windowRefService.getLocalData(key);
    }
    return ld?.recent || [];
  }

  set recentFeeds(value: aux.IMediaRow[]) {
    if (this.useCache) {
      const cookieToken = cfg.tokenKey('recent', this.currentAppName);
      if (cookieToken) {
        const key = cfg.localStorageTokenPatch(cookieToken, this.currentUser?.email || 'no@email',
          this.selectedAdvertiserId || 'noAdvertiser');
        this.windowRefService.setLocalData(key, {recent: value});
      }
    }
  }

  get isAddAdvertiserAvailable(): boolean {
    return this.selectedAdvertiser?.type !== models.EAdvertiserType.CLIENT;
  }
  get isAllPlanSelected(): boolean {
    return this.selectedAdvertiser?.signUpPlan === 'all';
  }
  get recentAccounts(): aux.IMediaRow[] {
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.RECENT_ACCOUNTS, this.currentUser?.email || 'no@email',
      this.selectedAdvertiserId || 'noAdvertiser');
    const ld = this.windowRefService.getLocalData(key);
    return ld?.recent || [];
  }

  set recentAccounts(value: aux.IMediaRow[]) {
    if (this.useCache) {
      const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.RECENT_ACCOUNTS, this.currentUser?.email || 'no@email',
        this.selectedAdvertiserId || 'noAdvertiser');
      this.windowRefService.setLocalData(key, {recent: value});
    }
  }

  get recentVersions(): aux.IMediaRow[] {
    const eTok = cfg.tokenKey('recent', this.currentAppName) || cfg.ELocalStorageTokens.RECENT_VERSIONS;
    const key = cfg.localStorageTokenPatch(eTok, this.currentUser?.email || 'no@email',
      this.selectedAdvertiserId || 'noAdvertiser', this.CacheMediaKey);
    const ld = this.windowRefService.getLocalData(key);
    return ld?.recent || [];
  }

  set recentVersions(value: aux.IMediaRow[]) {
    if (this.useCache) {
      const eTok = cfg.tokenKey('recent', this.currentAppName) || cfg.ELocalStorageTokens.RECENT_VERSIONS;
      const key = cfg.localStorageTokenPatch(eTok, this.currentUser?.email || 'no@email',
        this.selectedAdvertiserId || 'noAdvertiser', this.CacheMediaKey);
      this.windowRefService.setLocalData(key, {recent: value});
    }
  }

  updatePageByName(pageName: string | null): void {
    this.currentPage = this.findPageByName(pageName);
  }

  private findPageByName(pageName: string | null): aux.IMediaRow | any {
    if (this.allPages?.length && pageName) {
      return this.allPages.find((r: aux.IMediaRow) => r.subType.toLowerCase() === pageName.toLowerCase()) || null;
    }
    return null;
  }

  updateAllPages(allPages: aux.IMediaRow[], appName: string): void {
    this.allPages = allPages || [];
    if (this.allPages.length) {
      switch (appName) {
        case 'feeditor':
          this.updatePageByName('feedit');
          break;
        case 'effortless':
          this.updatePageByName('effortlessfeed');
          break;
        default:
          this.updatePageByName(this.currentPageName);
          break;
      }
    }
  }

  get isRMFAdvertiser(): boolean {
    return this.selectedAdvertiser?.companyName.toLowerCase() === 'adwordsapi-compliance';
  }

  get isSuperAdmin(): boolean {
    return this.currentUser?.role === 'admin' && cfg.SUPER_ADMIN_REGEX.test(this.currentUser?.email);
  }

  get isAdminRole(): boolean {
    return this.currentUser?.role === 'admin';
  }

  get isUsersAdminRole(): boolean {
    return this.currentUser?.role === 'usersAdmin';
  }

  get anyAdmin(): boolean {
    return this.isUsersAdminRole || this.isAdminRole;
  }
  get isModuleAvailable(): boolean {
    return this.checkModule(cfg.appNamePatch(this.currentAppName));
  }

  get isModuleAvailableIgnoreAdmin(): boolean {
    return this.checkModule(cfg.appNamePatch(this.currentAppName), true);
  }

  checkModule(moduleName?: string | null, ignoreAdminRole?: boolean): boolean {
    if (moduleName) {
      const module = cfg.appNamePatch(moduleName.replace('/', ''));
      return ['settings', 'effortless-marketing', 'my-cloud'].includes(module) || !!this.modules[module]
          || (!ignoreAdminRole && this.isAdminRole) || (cfg.MODULES_OPEN_IN_TRIAL.includes(module) && this.isPlanTrial);
    }
    return !ignoreAdminRole && this.isAdminRole;
  }

  get isFeeditorPlusAdvertiser(): boolean {
    return !!this.selectedAdvertiser?.modules?.feeditor_plus || this.isPlanTrial;
  }

  get isFeeditorAdvertiser(): boolean {
    return !!this.selectedAdvertiser?.modules?.feeditor || this.isPlanTrial;
  }


  get isFeeditorOrPlusSelected(): boolean {
    return  (this.planSettings?.plan || '').startsWith('feeditor') && !this.isPlanTrialOrExpired;
  }

  get isFeeditorPlanSelected(): boolean {
    return  (this.planSettings?.plan || '') === 'feeditor' && !this.isPlanTrialOrExpired;
  }

  get isFeeditorPlusPlanSelected(): boolean {
    return  (this.planSettings?.plan || '') === 'feeditor_plus' && !this.isPlanTrialOrExpired;
  }
  checkRouterLink(link: string): boolean {
    return this.checkModule(link);
  }
  isAdvertiserHasPermissionForModule(pageModule: string,
                                     pageName: string, adminOnly: boolean, bingEnabled: boolean): boolean {
    if (!bingEnabled && this.channelIsBing) {
      return false;
    }
    if (pageModule === 'rmf' && this.isRMFAdvertiser) {
      return true;
    }
    if (pageModule === 'rmf' && this.isSuperAdmin) {
      return false;
    }
    const level = this.modules[pageModule];
    if (level && adminOnly) {
      return this.isAdminRole;
    }
    if (pageModule === 'effortless_feed' && !level) {
      return level;
    }
    const pages = (pageModule !== 'rmf') ? cfg.DEFAULT_MODULES_LIST.find((item) => item.module === pageModule)?.pages || [] : [];
    return (['starter', 'standard'].indexOf(level) > -1 && pageModule !== 'rmf') ?
      pages.includes(pageName.toLowerCase()) : !!level || this.isSuperAdmin;
  }

  getTabs(translator: any): aux.IMediaRow[] {
    const res = this.allPages.reduce((arr: aux.IMediaRow[], p: aux.IMediaRow) => {
      const copyTab = _.cloneDeep(p);
      // (p.value?.appName === this.currentAppName && this.isSuperAdmin) ||
      if (
          (!p.value?.deprecated && p.value?.appName === this.currentAppName && p.value?.modules.some((m: string) => {
            return this.isAdvertiserHasPermissionForModule(m, p.value?.name, p.value?.adminOnly, p.value?.bingEnabled);
          }))) {
        copyTab.label = (translator) ? translator(p.label) : p.label;
        // copyTab.tooltip = (translator) ? translator(`ng-tooltip-${p.subType}`) : `ng-tooltip-${p.subType}`;
        arr.push(copyTab);
      }
      return arr;
    }, []);
    // Manually added Cockpit tab
    res.push({
      key: '', // "654106b7d90964644bdacfb3",
      label: 'Cockpit',
      subType: aux.EMediaSubType.COCKPIT,
      type: aux.EMediaType.NONE,
      value: {
        visible: true,
        appName: 'views',
        modules: ['adcore'],
        name: 'Cockpit',
        order: 3
      },
      visible: true
    });
    res.sort((a, b) => {
      return a.value.order - b.value.order;
    });
    return res;
  }

  initNoCachedMedia(mediaId?: string, mediaType?: aux.EMediaType): void {
    if (mediaId && mediaType) {
      const media: aux.IMediaRow = {
        key: mediaId,
        label: '',
        value: {},
        type: mediaType || aux.EMediaType.NONE,
        subType: aux.EMediaSubType.NONE,
        visible: true
      };
      switch (mediaType) {
        case aux.EMediaType.ADVERTISER:
          this.currentAdvertiser = media;
          break;
        case aux.EMediaType.ACCOUNT:
          this.currentAccount = media;
          break;
        case aux.EMediaType.FEED:
        case aux.EMediaType.FEED_PLUS:
          this.currentFeed = media;
          break;
        case aux.EMediaType.VERSION:
          this.currentVersion = media;
          break;
      }
    }
  }

  get versionTimePeriod(): aux.ITimePeriod | null {
    if (this.currentVersionSettings?.timePeriod) {
      const tp = _.cloneDeep(this.currentVersionSettings?.timePeriod);
      let timePeriodType: any = tp?.type;
      let customDate: aux.ICustomDate | undefined = ((tp?.from && tp?.to) || tp?.deltaToday || tp?.deltaYesterday) ? {
        from: tp.from,
        to: tp.to,
        deltaToday: tp.deltaToday,
        deltaYesterday: tp.deltaYesterday
      } : undefined;
      if (timePeriodType === 'LAST_90_DAYS') {
        timePeriodType = aux.ETimePeriod.CUSTOM_DATE_UP_TO_YESTERDAY;
        customDate = {
          deltaYesterday: 90
        };
      } else if (timePeriodType === 'LAST_180_DAYS') {
        timePeriodType = aux.ETimePeriod.CUSTOM_DATE_UP_TO_YESTERDAY;
        customDate = {
          deltaYesterday: 180
        };
      } else if (timePeriodType === 'LAST_60_DAYS') {
        timePeriodType = aux.ETimePeriod.CUSTOM_DATE_UP_TO_YESTERDAY;
        customDate = {
          deltaYesterday: 60
        };
      }
      return {
        type: timePeriodType || aux.ETimePeriod.YESTERDAY,
        customDate
      };
    }
    return null;
  }

  get currentVersionSettings(): any {
    return this.currentVersion?.value?.settings;
  }

  get currentFeeditorVersionDataMashup(): any[] {
    return this.currentVersionSettings?.dataMashup || [];
  }

  updateSpecificDataMashup(dataMashup: any, dataSource?: string): void {
    if (dataMashup && this.currentFeeditorVersionDataMashup?.length) {
      const i: number = this.currentFeeditorVersionDataMashup.findIndex((d) => d.dataSource === dataMashup.dataSource);
      if (i !== -1) {
        this.currentFeeditorVersionDataMashup.splice(i, 1, dataMashup);
      } else {
        this.currentFeeditorVersionDataMashup.push(dataMashup);
      }
    } else if (dataMashup && !this.currentFeeditorVersionDataMashup?.length) {
      this.currentVersionSettings.dataMashup = [dataMashup];
    } else if (!dataMashup && dataSource && this.currentFeeditorVersionDataMashup?.length) {
      const i: number = this.currentFeeditorVersionDataMashup.findIndex((d) => d.dataSource.indexOf(dataSource) > -1);
      if (i !== -1) {
        this.currentFeeditorVersionDataMashup.splice(i, 1);
      }
    }
  }

  get currentFeeditorVersionGpc(): any[] {
    return this.currentVersionSettings?.gpc || [];
  }

  get currentTimePeriod(): aux.ITimePeriod | null {
    if (!this._currentTimePeriod) {
      const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_TIME_PERIOD,
        this.currentUser?.email || 'no@email', this.currentAppName || 'noApp');
      this._currentTimePeriod = this.versionTimePeriod || this.windowRefService.getLocalData(key) || {type: aux.ETimePeriod.YESTERDAY};
    }
    return (this.currentAppName && cfg.APPS_WITH_ACCOUNT.includes(this.currentAppName)) ? this._currentTimePeriod : null;
  }

  set currentTimePeriod(value: aux.ITimePeriod | null) {
    this._currentTimePeriod = value;
    const key = cfg.localStorageTokenPatch(cfg.ELocalStorageTokens.LAST_TIME_PERIOD,
      this.currentUser?.email || 'no@email', this.currentAppName || 'noApp');
    this.windowRefService.setLocalData(key, value);
  }


  get versionDashboard(): any {
    return this.currentVersion?.value?.dashboard || {};
  }

  get feeditorToolBar(): any {
    if (this.currentVersion && this.currentVersion.value && !this.currentVersion.value.toolbar) {
      this.currentVersion.value.toolbar = {};
    }
    return this.currentVersion?.value?.toolbar || {};
  }

  set feeditorToolBar(value: any) {
    if (this.currentVersion && this.currentVersion.value) {
      this.currentVersion.value.toolbar = value;
    }
  }

  get isItDemoAdvertiser(): boolean {
    return this.selectedAdvertiserId === cfg.DEMO_ADVERTISER_ID;
  }

  get isDynamicSearchAllEnabled(): boolean {
    return this.modules.adcore === 'pro';
  }

  get isDynamicSearchEnabled(): boolean {
    return [aux.EMediaSubType.AD, aux.EMediaSubType.KEYWORD].includes(this.currentPage?.subType || aux.EMediaSubType.NONE)
      && this.modules.adcore === 'pro';
  }

  get isShoppingEnabled(): boolean {
    return this.currentPage?.subType === aux.EMediaSubType.PRODUCT && this.modules.feeditor === 'pro';
  }


  get isSTEnabled(): boolean {
    return this.currentPage?.subType === aux.EMediaSubType.SEARCHQUERY && this.modules.adcore === 'pro';
  }

  get isShoppingAllEnabled(): boolean {
    return this.modules.adcore === 'pro';
  }

  get isRules(): boolean {
    return (this.currentPage?.subType === aux.EMediaSubType.CAMPAIGN) ? !!this.modules.adcore : this.modules.adcore === 'pro';
  }

  buildSemdocUrlParams(): void {
    this.semdocUrlWithParameters = '';
    if (this.currentPage?.subType !== aux.EMediaSubType.ASSETS) {
      let param: any = {
        adCoreToken: this.accessToken,
        adCoreChannelToken: {
          name: this.currentChannelName,
          id: null, _id: this.currentAccountChannel?.key
        },
        adCoreAccountToken: {
          name: this.currentAccount?.label,
          entityId: this.currentAccount?.customerId,
          _id: this.currentAccount?.key
        },
        adCoreCampaignToken: {name: '', entityId: '', _id: ''},
        adCoreMccToken: {
          name: this.currentAccountParent?.label,
          entityId: this.currentAccountParent?.customerId,
          _id: this.currentAccountParent?.key
        },
        userId: this.currentUser?._id || '',
        userRole: this.currentUser?.role || '',
        adCoreUserNameToken: this.currentUser?.email || '',
        module: this.currentPage?.subType,
        advertiserId: this.selectedAdvertiserId || '',
        timePeriod: this.emitTimePeriod,
        language: this.currentUser?.language || 'en',
        debugMode: false,
        channelName: this.currentChannelName,
      };
      param = JSON.stringify(param);
      param = btoa(unescape(encodeURIComponent(param)));
      const host = this.windowRefService.host;
      this.semdocUrlWithParameters = `${environment.adcoreUI}/public/semdoc/?param=${param}`;
    }
  }

  get emitTimePeriod(): models.IOldTimePeriod {
    return {
      type: this.currentTimePeriod?.type || '',
      fromDate: this.currentTimePeriod?.customDate?.from,
      toDate: this.currentTimePeriod?.customDate?.to,
      from: this.currentTimePeriod?.customDate?.from,
      to: this.currentTimePeriod?.customDate?.to,
      deltaToday: this.currentTimePeriod?.customDate?.deltaToday,
      deltaYesterday: this.currentTimePeriod?.customDate?.deltaYesterday
    };
  }

  get planSettings(): models.IPlanSettings {
    return this.selectedAdvertiser?.planSettings;
  }

  get mediaBlastPaymentProfile(): models.IMediaBlastPaymentProfile {
    return this.selectedAdvertiser?.mediaBlastPaymentProfile || {};
  }

  get selectedAdvertiserUserSelectedPlan(): string {
    const plan = this.selectedAdvertiser?.signUpPlan || 'all';
    if (plan === 'all') {
      return 'feeditor';
    } else {
      return plan;
    }
  }

  get isPlanTrial(): boolean {
    return this.planSettings?.subscriptionType === models.ESubscriptionType.TRIAL;
  }

  get isPlanTrialOrExpired(): boolean {
    return this.planSettings?.subscriptionType === models.ESubscriptionType.TRIAL ||
      this.planSettings?.subscriptionType === models.ESubscriptionType.EXPIRED;
  }

  get isPlanCanceled(): boolean {
    return this.planSettings?.subscriptionType === models.ESubscriptionType.CANCELED;
  }
  get daysPlanSubscriptionEnd(): number {
    return utils.momentDiff(this.planSettings?.subscriptionEnd, this._today);
  }

  get monthsPlanSubscriptionEnd(): number {
    return utils.momentDiff(this.planSettings?.subscriptionEnd, this._today, 'months');
  }

  get daysPlanSubscriptionPassed(): number {
    return utils.momentDiff( this._today, this.planSettings?.subscriptionStart);
  }
  get daysPlanSubscriptionTotal(): number {
    return utils.momentDiff(this.planSettings?.subscriptionEnd, this.planSettings?.subscriptionStart);
  }
  get isPlanSubscriptionEnded(): boolean {
    return this.planSettings?.isLicenseOwner === 'yes' && this.daysPlanSubscriptionEnd <= 0;
  }

  get noAccessBecauseOfSubscriptionEnd(): boolean {
    return this.isPlanSubscriptionEnded && !this.isAdminRole;
  }

  get currentUserHashNumber(): number {
    const tmpUserHash = 1234567890;
    const hashStr = `${this.currentUser?.id || this.currentUser?._id || 'empty'}_${this.currentUser?.email || 'empty@empty.com'}`;
    let userHash = Math.abs(utils.hashCode(hashStr));
    if (userHash.toString().length !== tmpUserHash.toString().length) {
      userHash = tmpUserHash;
    }
    return userHash;
  }

}
